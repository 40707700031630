'use strict';

module.exports = function () {
    $(document).ready(function () {
        // fix for buggy mobile menu scroll
        const targetNode = document.querySelector('.main-header');
        const observer = new MutationObserver(function (mutationsList) {
            mutationsList.forEach(function (mutation) {
                if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
                    if ($(targetNode).hasClass('menu-in')) {
                        $('#maincontent .experience-region > .experience-component.experience-livestoryContentAsset').css('overflow', 'hidden');
                    } else {
                        $('#maincontent .experience-region > .experience-component.experience-livestoryContentAsset').css('overflow', '');
                    }
                }
            });
        });
        observer.observe(targetNode, { attributes: true });
        //injecting PD code in mobile menu accordions
        switch (window.language) {
            case 'it':
                $('#legals-accordion').html($('.accordion-content#area-legale').html());
                break;
            case 'en':
                $('#legals-accordion').html($('.accordion-content#legal-area').html());
                break;
            case 'es':
                $('#legals-accordion').html($('.accordion-content#área-legal').html());
                break;
            default:
                $('#legals-accordion').html($('.accordion-content#legal-area').html());
                break;
        }
    });
};
