'use strict';

const { default: Swiper, A11y, Autoplay, Navigation, Pagination } = require("swiper");
Swiper.use([Navigation, Pagination, Autoplay, A11y]);

function initEinsteinCarousel($carousel) {
    if (!$carousel.hasClass('swiper-initialized')) {
        var configs = {
            slidesPerView: $carousel.data('option-slidesperview') || 5,
            slidesPerGroup: $carousel.data('option-slidespergroupdesktop') || 5,
            centeredSlides: $carousel.data('option-centeredslides') || false,
            loop: $carousel.data('option-loop') || true,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            watchOverflow: $carousel.data('option-watchoverflow') || true,
            breakpoints: {
                320: {
                    slidesPerView: $carousel.data('option-slidesperviewmobile') || 1.5,
                    slidesPerGroup: $carousel.data('option-slidespergroup') || 1,
                },
                1024: {
                    slidesPerView: $carousel.data('option-slidesperview') || 5,
                    slidesPerGroup: $carousel.data('option-slidespergroupdesktop') || 5,
                    centeredSlides: $carousel.data('option-centeredslidesdesktop') || false,
                },
            },
            lazy: true
        };
        new Swiper($carousel[0], configs);
    }
}

function observeCarousels() {

    $('.einstein-products-carousel').each(function () {
        var $swiperCo = $(this);
        var $swiperEl = $swiperCo.find('.swiper.is-einstein-carousel:not(.swiper-initialized)');

        if ($swiperEl.length > 0) {
            var $swiperWr = $swiperEl.find('.swiper-wrapper.js-swiper-wrapper');
            if ($swiperWr.find('.product').length > 0) {
                initEinsteinCarousel($swiperEl);
            } else {
                var observer = new MutationObserver(function (mutations, obs) {
                    if ($swiperWr.find('.product').length > 0) {
                        initEinsteinCarousel($swiperEl);
                        obs.disconnect();
                    }
                });
                observer.observe($swiperCo[0], { childList: true, subtree: true });
            }
        } else {
            var observer = new MutationObserver(function (mutations, obs) {
                var $swiperEl = $swiperCo.find('.swiper.is-einstein-carousel:not(.swiper-initialized)');
                var $swiperWr = $swiperEl.find('.swiper-wrapper.js-swiper-wrapper');
                if ($swiperWr.find('.product').length > 0) {
                    initEinsteinCarousel($swiperEl);
                    obs.disconnect();
                }
            });
            observer.observe($swiperCo[0], { childList: true, subtree: true });
        }
    });
}

module.exports = $(function () {
    observeCarousels();
});